export enum Type {
  Person,
  Project,
  Event
}

export enum ProjectType {
  Education,
  Lapp,
  Tool,
  Wallet
}
